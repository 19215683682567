<template>
  <b-card :header="cardHeader">
    <spinner-loader :loading="loading" />

    <div
      v-if="event.user"
      class="mb-2"
    >
      <strong
        class="my-2"
      >Suggested by: </strong>
      <b-link :to="{name:'admin-mobile-users-view',params:{id:event.user.id}}">
        {{ suggestedBy }}
      </b-link>
    </div>

    <div
      v-if="event.publish_date"
      class="mb-2"
    >
      <strong
        class="my-2"
      >Published at: </strong>
      <span>
        {{ event.publish_date }}
      </span>
    </div>

    <community-event-form
      v-if="!loading"
      :event="event"
      :submit="editEvent"
      :disabled="disabled"
    />
  </b-card>
</template>
<script>
import CommunityEventForm from '@/common/components/CommunityEvents/CommunityEventForm.vue'
import communityEvent from '@/common/compositions/CommunityEvents/communityEventApi'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'

export default {
  name: 'EditCommunityEvent',
  components: { CommunityEventForm, SpinnerLoader },
  props: {
    disabled: { type: Boolean, default: false },
    cardHeader: { type: String, default: '' },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    suggestedBy() {
      return `${this.event.user?.profile?.first_name} ${this.event.user?.profile?.last_name}`
    },
  },
  setup() {
    const { event, editCommunityEvent, getCommunityEvent } = communityEvent()
    return { event, editCommunityEvent, getCommunityEvent }
  },
  created() {
    this.loading = true
    this.getCommunityEvent(this.$route.params.id).finally(() => {
      this.loading = false
    })
  },
  methods: {
    editEvent() {
      return this.editCommunityEvent(this.$route.params.id).then(() => {
        this.$router.back()
      })
    },
  },
}
</script>
<style lang="">

</style>
